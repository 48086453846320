import { FC } from 'react';

import { PlatformID } from '@common/clients/api';
import { useTranslation } from '@pxr/i18n';
import { PXRLogo } from '@web/molecules/PXRLogo';
import { PlatformLogoList } from '@web/organisms/Footer/molecules';

import styles from './BottomBar.module.scss';

interface Props {
    platformID: PlatformID;
    platformName: string;
}

const getCopyrightName = (platformID: PlatformID) => {
    switch (platformID) {
        case PlatformID.VP:
            return 'Voetbalprimeur B.V. ';
        case PlatformID.VN:
            return 'SportPrimeur';
        case PlatformID.GP:
            return 'Autosport International';
        case PlatformID.BR:
            return 'Bright';
        case PlatformID.VI:
            return 'Voetbal International';
        default:
            return 'PXR';
    }
};

export const BottomBar: FC<Props> = ({ platformID }) => {
    const __footer = useTranslation('footer').t;

    const copyrightText = `© ${new Date().getFullYear()} ${getCopyrightName(platformID)} B.V.`;

    return (
        <div className={`${styles.BottomBar}`}>
            <div className={`${styles['content-wrapper']} full-width`}>
                <h4 className={styles.copyright}>{copyrightText}</h4>
                <div className={styles['network-platforms']}>
                    <h4>{__footer('label.part-of')}</h4>
                    <PXRLogo />
                </div>
                <PlatformLogoList currentPlatform={platformID} />
            </div>
        </div>
    );
};

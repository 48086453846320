'use client';

import { FC, memo, useMemo } from 'react';

import { Link } from '@common/atoms/Link';
import { PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { checkIfModuleSectionEnabeled } from '@common/utils/betting/checkIfBettingEnabled';
import { useTranslation } from '@pxr/i18n';
import { PlatformLogo } from '@web/atoms/PlatformLogo/PlatformLogo';
import { getSocialLinks, SocialLinkBlock } from '@web/molecules/SocialLinkBlock';
import { LinkList } from '@web/organisms/Footer/molecules/LinkList/LinkList';
import { Route } from '@web/routing';

import { GridWrapper } from './atoms/GridWrapper';
import { GridItem, GridWrapperLayout } from './atoms';
import { AppUrls, BottomBar } from './molecules';

import styles from './Footer.module.scss';

export const MAXIMUM_TRENDING_TAGS_TO_SHOW = 8;
export const MINIMUM_TRENDING_TAGS_TO_SHOW = 6;

const Component: FC = () => {
    const { context, platform, trendingTags, modulesBySection, route } = useContextData();

    const __footer = useTranslation('footer').t;
    const __betting = useTranslation('betting').t;
    const __url = useTranslation('url').t;

    const allowBetting = checkIfModuleSectionEnabeled(modulesBySection);
    const socialLinks = useMemo(() => getSocialLinks(context), [context]);
    const tagsToShow: number = allowBetting ? MINIMUM_TRENDING_TAGS_TO_SHOW : MAXIMUM_TRENDING_TAGS_TO_SHOW;
    const trendingLinks = useMemo(() => {
        if (!trendingTags) return [];

        return trendingTags.slice(0, tagsToShow).map(({ tagTypeSlug, tagSlug, title }) => ({
            url: `${tagTypeSlug}/${tagSlug}`,
            label: title,
        }));
    }, [tagsToShow, trendingTags]);

    if (route === Route.Signin) {
        return null;
    }

    return (
        // FIXME: PB-5484 - Add newsletter sign up component above (or inside) footer
        <footer className={`${styles.Footer} _gp-redesign _including-children`}>
            <GridWrapper classes={'wrapper full-width'}>
                <GridItem gridArea={GridWrapperLayout.PLATFORM_BRAND}>
                    <Link href="/">
                        <PlatformLogo extraPadding classes={styles.spacer} platformID={platform.id} />
                    </Link>
                    {context.androidAppUrl || context.iosAppUrl ? (
                        <h3>{__footer('label.download-app')}</h3>
                    ) : null}
                    <AppUrls />
                </GridItem>
                <GridItem gridArea={GridWrapperLayout.ABOUT}>
                    <h3>{__footer('title.about-the-website')}</h3>
                    <LinkList
                        links={[
                            { url: __url('colophon'), label: __footer('label.colophon') },
                            { url: __url('vacancies'), label: __footer('label.work-at') },
                            { url: __url('rss-feeds'), label: __footer('label.rss-feeds') },
                            { url: __url('terms-of-service'), label: __footer('label.terms-conditions') },
                            {
                                url: __url('privacy-statement'),
                                label: __footer('label.privacy-statement'),
                            },
                        ]}
                    />
                </GridItem>
                <GridItem gridArea={GridWrapperLayout.TRENDING} title={__footer('title.trending')}>
                    {trendingLinks ? <LinkList links={trendingLinks} isPrefixed /> : null}
                </GridItem>
                <GridItem gridArea={GridWrapperLayout.SOCIAL} title={__footer('title.follow-us')}>
                    <SocialLinkBlock socialLinks={socialLinks} platformID={platform.id} />
                </GridItem>
                <GridItem gridArea={GridWrapperLayout.CONTACT} title={__footer('title.contact')}>
                    <LinkList
                        links={[
                            { url: 'contact', label: __footer('label.contact') },
                            { url: __url('tip-the-editors'), label: __footer('label.tip-editors') },
                        ]}
                    />
                    <h3>{__footer('title.commerce-and-partnerships')}</h3>
                    <LinkList links={[{ url: __url('advertise'), label: __footer('label.advertise') }]} />
                    {allowBetting ? (
                        <LinkList
                            links={[
                                {
                                    url: __url('play-responsibly'),
                                    label: __betting('label.play-responsible'),
                                },
                                {
                                    url: __url('play-responsibly'),
                                    label: __betting('label.betting-settings'),
                                },
                            ]}
                        />
                    ) : null}
                </GridItem>
            </GridWrapper>
            <BottomBar platformID={platform.id} platformName={platform.name || PlatformID.VP} />
        </footer>
    );
};

Component.displayName = 'Footer';
export const Footer = memo(Component);
